import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import CircularProgressWithLabel from './../lists/CirtcularProgressWithLabel';
import './MasterListViewPage.css';

import { Link } from 'react-router-dom';
import getCWSService from '../../app/getCWSService';
import { loadLists, runDealerLists } from './masterlist.slice';

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useState } from 'react';
import { PureComponent } from 'react';

class TotalChart extends PureComponent {
    render() {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={this.props.data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="prequalified" stackId="1" stroke="#000" fill="#75C8F6" label={{ fill: 'red', fontSize: 20 }} />
            <Area type="monotone" dataKey="eligible" stackId="1" stroke="#000" fill="#F58400" />
            <Area type="monotone" dataKey="certified" stackId="1" stroke="#000" fill="#FFCC11" />
          </AreaChart>
        </ResponsiveContainer>
      );
    }
  }
  

const MasterListViewPage = (props) => {

    const dispatch = useDispatch();

    const { 
        lists,
        totalStats,
    } = useSelector(state => state.masterLists);


    useEffect(async () => {

        const { getUserProfile } = getCWSService();
        const profile = await getUserProfile();
        console.log(profile);

        dispatch(loadLists());

    }, [dispatch]);    

    const handleRunDealerLists = () => {
        dispatch(runDealerLists());
    };

    const { getUserProfileSync } = getCWSService();
    const profile = getUserProfileSync();
    console.log(profile);
    
    return (
        <div className="container container-search-results">

            <div className="row dashboard-row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header" style={{ height: "100px" }}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="mt-3 mb-3">
                                        Total pre-qualified machines vs number of eligible machines
                                    </div>
                                    <div style={{ marginTop: "20px", marginBottom: "40px" }}>
                                        <span style={{ fontSize: "13px" }}>Pre-qualified machines <span style={{ backgroundColor: "#75C8F6", display: "inline-block", width: "10px", height: "10px", borderRadius: "5px"}}></span></span>
                                        <span style={{ fontSize: "13px", marginLeft: "25px" }}>Eligible machines <span style={{ backgroundColor: "#F58400", display: "inline-block", width: "10px", height: "10px", borderRadius: "5px"}}></span></span>
                                        <span style={{ fontSize: "13px", marginLeft: "25px" }}>Certified machines <span style={{ backgroundColor: "#FFCC11", display: "inline-block", width: "10px", height: "10px", borderRadius: "5px"}}></span></span>
                                    </div>
                                </div>
                                <div className="col-sm-4"></div>
                                <div className="col-sm-2">
                                </div>
                            </div>
                        
                        </div>
                        <div className="card-body" style={{height: "400px"}}>
                            
                            <TotalChart data={totalStats}></TotalChart>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="mt-3 mb-3">
                                        Dealer Pre-Qualification List (updated on 09/30/2024)
                                    </div>
                                </div>
                                <div className="col-sm-4"></div>
                                <div className="col-sm-2">
                                </div>
                            </div>
                        
                        </div>
                        <div className="card-body">
                            
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="w-10">Dealer Name</th>
                                        <th className="w-10">Total machines</th>
                                        <th className="w-10">No. of Pre-qualified Machines</th>
                                        <th className="w-10">No. of Eligible Machines</th>
                                        <th className="w-10">No. of Certified Machines</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lists.map(list => {
                                        const createdOn = new Date(list.createdOn);
                                        return (<tr key={list.id}>
                                            {/* <td>{list.status === "In progress" ? 
                                                (<span style={{ fontWeight: "bold" }}>{list.dealerName} ({list.dealerCode})
                                                    <span style={{color:"red", marginLeft: "15px"}}>
                                                    <span className="warning-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                                        </svg>&nbsp;
                                                    </span>
                                                        In progress
                                                    </span> 
                                                </span>) 
                                            : (<Link to={`/master-lists/${list.id}`} style={{ fontWeight: "bold" }}>{list.dealerName} ({list.dealerCode})</Link>) } </td> */}
                                            <td><Link to={`/master-lists/${list.id}`} style={{ fontWeight: "bold" }}>{list.dealerName} ({list.dealerCode})</Link></td>
                                            <td>{list.totalMachines}</td>
                                            <td>{list.passedPrequalification}</td>
                                            <td>{list.eligible}</td>
                                            <td>{list.certified}</td>
                                        </tr>);
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default withRouter(MasterListViewPage);