import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image, Svg, Path, Polygon } from '@react-pdf/renderer';
import { format } from 'date-fns';

import roboto from './../../resources/roboto/Roboto-Regular.ttf';
import robotoBold from './../../resources/roboto/Roboto-Medium.ttf';
import robotoReallyBold from './../../resources/roboto/Roboto-Bold.ttf';

import noto from './../../resources/noto-sans-sc/NotoSansSC-Regular.ttf';
import notoBold from './../../resources/noto-sans-sc/NotoSansSC-Medium.ttf';
import notoReallyBold from './../../resources/noto-sans-sc/NotoSansSC-Bold.ttf';

import cairo from './../../resources/cairo/Cairo-Regular.ttf';
import cairoBold from './../../resources/cairo/Cairo-SemiBold.ttf';
import cairoReallyBold from './../../resources/cairo/Cairo-Bold.ttf';

import catlogocertificate from './../../resources/cat-logo-certificate.png';

import certificateHeaderImageEng from './../../resources/certificate-header/CCM_Report_Header_English.png';
import certificateHeaderImageAra from './../../resources/certificate-header/CCM_Report_Header_Arabic.png';
import certificateHeaderImageChi from './../../resources/certificate-header/CCM_Report_Header_Chinese.png';
import certificateHeaderImageFre from './../../resources/certificate-header/CCM_Report_Header_French.png';
import certificateHeaderImagePor from './../../resources/certificate-header/CCM_Report_Header_Portuguese.png';
import certificateHeaderImagePol from './../../resources/certificate-header/CCM_Report_Header_Polish.png';
import certificateHeaderImageSpa from './../../resources/certificate-header/CCM_Report_Header_Spanish.png';
import certificateHeaderImageDe from './../../resources/certificate-header/CCM_Report_Header_German.png';
import certificateHeaderImageCz from './../../resources/certificate-header/CCM_Report_Header_Czech.png';
import certificateHeaderImageTr from './../../resources/certificate-header/CCM_Report_Header_Turkish.png';
import certificateHeaderImageSk from './../../resources/certificate-header/CCM_Report_Header_Slovak.png';

import certificateHeaderImage from './../../resources/ccm-report-header.png';


Font.register({ family: 'Roboto', src: roboto });
Font.register({ family: 'Roboto-Bold', src: robotoBold });
Font.register({ family: 'Roboto-Really-Bold', src: robotoReallyBold });

Font.register({ family: 'Noto', src: noto });
Font.register({ family: 'Noto-Bold', src: notoBold });
Font.register({ family: 'Noto-Really-Bold', src: notoReallyBold });

Font.register({ family: 'Cairo', src: cairo });
Font.register({ family: 'Cairo-Bold', src: cairoBold });
Font.register({ family: 'Cairo-Really-Bold', src: cairoReallyBold });

const formatDate = (date, language) => {
    const months = {
        en_US: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        por: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        ara: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"],
        zh_CN: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
        pl_PL: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
        es_ES: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        fr: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        de: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        cz: ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
        tr: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
        sk: ["Január", "Február", "Marec", "Apríl", "Máj", "Jún", "Júl", "August", "September", "Október", "November", "December"]
    };
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const monthName = months[language][monthIndex];
    return `${day} ${monthName} ${year}`;
};

const CertificatePDF = (props) => {

    if(!props.certificateDetails || !props.certificateDetails.validatedItems) return (<View></View>);

    console.log("Generating certificate", props);

    let pdfFont = "Roboto";
    let pdfBoldFont = "Roboto-Bold";
    let pdfReallyBoldFont = "Roboto-Really-Bold";

    let isRTL = false;

    if(props.languageCode === "zh_CN"){
        pdfFont = "Noto";
        pdfBoldFont = "Noto-Bold";
        pdfReallyBoldFont = "Noto-Really-Bold";
    }

    if(props.languageCode === "ara"){
        pdfFont = "Cairo";
        pdfBoldFont = "Cairo-Bold";
        pdfReallyBoldFont = "Cairo-Really-Bold";
        isRTL = true;
    }

    let headerImage = certificateHeaderImage;

    switch(props.languageCode){
        case "en_US":
            headerImage = certificateHeaderImageEng;
            break;
        case "por":
            headerImage = certificateHeaderImagePor;
            break;
        case "ara":
            headerImage = certificateHeaderImageAra;
            break;
        case "zh_CN":
            headerImage = certificateHeaderImageChi;
            break;
        case "pl_PL":
            headerImage = certificateHeaderImagePol;
            break;
        case "es_ES":
            headerImage = certificateHeaderImageSpa;
            break;
        case "fr":
            headerImage = certificateHeaderImageFre;
            break;
        case "de":
            headerImage = certificateHeaderImageDe;
            break;
        case "cz":
            headerImage = certificateHeaderImageCz;
            break;
        case "tr":
            headerImage = certificateHeaderImageTr;
            break;
        case "sk":
            headerImage = certificateHeaderImageSk;
            break;
        default:
            break;
    }

    const rowNumber = props.certificateDetails.validatedItems.length;
    const blankContent = (8 - rowNumber) * 19;

    const styles = StyleSheet.create({

        // Page
        page: {
            backgroundColor: '#FFFFFF',
            color: "#43485C",
            fontFamily: pdfFont,
            height: "279mm"
        },
    
        // Header
        headerSection: {
            padding: 20,
            borderBottom: "1px solid #E6E9F0",
            height: "35mm",
            display: 'flex',
            flexDirection: 'row'
        },
        headerColumn: {
            display: 'flex',
            flexDirection: 'col',
            flexBasis: "100%",
            flex: 1,
            marginLeft: "4mm"
        },
        catLogoImage: {
            width: "39mm",
            height: "6mm",
            marginLeft: "10mm", 
            marginTop: "17mm" 
        },
        cvaIconImage: {
            width: "13mm",
            height: "13mm",
            marginTop: "4mm",
            marginLeft: "70mm"
        },
        ccmIcon: {
            width: "62mm",
            height: "29mm",
            marginLeft: "3mm",
            marginTop: "15.5mm"
        },
    
        // Summary
        summarySection: {
            padding: "20 0 20 0",
            borderBottom: "1px solid #E6E9F0",
            height: "74mm",
            width: "90%",
            marginLeft: "5%"
        },
        summaryRowFirst: {
            display: 'flex',
            flexDirection: 'row',
            width: "100%",
            flex: 1,
            borderBottom: "1px solid #F7D630",
        },
        summaryRow: {
            display: 'flex',
            flexDirection: 'row',
            width: "100%",
            flex: 1,
            paddingTop: "7mm"
        },
        summaryColumn: {
            display: 'flex',
            flexDirection: 'col',
            flexBasis: "100%",
            flex: 1
        },
        summaryTitle: {
            fontFamily: pdfReallyBoldFont,
            color: "#000",
            fontSize: isRTL ? 17 : 25,
            fontWeight: "bold",
            marginTop: "-2mm",
            textAlign: isRTL ? 'right' : 'left'
        },
        certIconImage: {
            width: "8mm",
            height: "8mm",
        },
        summaryItemTitle: {
            fontFamily: pdfBoldFont,
            fontSize: 8,
            color: "#78869E",
            textAlign: isRTL ? 'right' : 'left'
        },
        summaryItemContent: {
            fontSize: 11,
            fontFamily: pdfBoldFont,
            marginTop: "2mm",
            textAlign: isRTL ? 'right' : 'left'
        },
    
        // History
        historySection: {
            padding: "20 0 0 0",
            height: `${rowNumber * 19}mm`,
            width: "90%",
            marginLeft: "5%"
        },
        historyTitle: {
            fontFamily: pdfBoldFont,
            fontSize: 11,
            color: "#78869E",
            height: "10mm",
            textAlign: isRTL ? 'right' : 'left'
        },
        historyTitleRow: {
            display: 'flex',
            flexDirection: 'row',
            width: "100%",
            flex: 1,
            maxHeight: "8mm"
        },
        historyRow: {
            display: 'flex',
            flexDirection: 'row',
            width: "100%",
            flex: 1
        },
        historyColumnFirst: {
            display: 'flex',
            flexDirection: 'col',
            flexBasis: "100%",
            flex: 0.6,
            borderBottom: "1px solid #E6E9F0",
            marginTop: "2mm",
            marginBottom: "3mm",
            textAlign: isRTL ? 'right' : 'left'
        },
        historyColumn: {
            display: 'flex',
            flexDirection: 'col',
            flexBasis: "100%",
            flex: 0.7,
            borderBottom: "1px solid #E6E9F0",
            marginTop: "2mm",
            marginBottom: "3mm",
            textAlign: isRTL ? 'right' : 'left'
        },
        historyColumnLast: {
            display: 'flex',
            flexDirection: 'col',
            flexBasis: "100%",
            flex: 2.3,
            justifyContent: "left",
            borderBottom: "1px solid #E6E9F0",
            marginTop: "2mm",
            marginBottom: "3mm",
            textAlign: isRTL ? 'right' : 'left'
        },
        historyColumnLastRow: {
            display: 'flex',
            flexDirection: 'col',
            flexBasis: "100%",
            flex: 1,
            marginTop: "3mm",
            marginBottom: "3mm",
            textOverflow: "initial",
            overflow: "visible",
        },
        tableHeaderText: {
            fontFamily: pdfBoldFont,
            fontSize: 9
        },
        tableContentText: {
            fontSize: 9,
            maxLines: 5,
            textOverflow: "initial",
            overflow: "visible",
            width: "100%"
        },
    
        // Footer
        footerSection: {
            padding: "20 0 20 0",
            height: "40mm",
            width: "67.5%",
            marginLeft: "5%",
            marginTop: "7mm"
        },
        footerLink: {
            fontSize: !!isRTL ? 8 : 10,
            color: "#000",
            marginTop: "2mm",
            marginLeft: !!isRTL ? "15%" : "7%",
            fontFamily: pdfBoldFont,
            width: "85%",
            textAlign: isRTL ? 'right' : 'left'
        },
        footerText: {
            fontSize: 6,
            color: "#43485C",
            marginTop: "2mm",
            textAlign: isRTL ? 'right' : 'left',
            direction: "rtl",
            width: "100mm"
        },
        linkColor: {
            fontFamily: pdfBoldFont,
            color: "#1991EB"
        },
        footerLine: {
            backgroundColor: "#F7D630",
            width: "100%",
            height: "3mm",
            marginTop: "3mm"
        },

        // Spacer
        blankContent: {
            height: `${blankContent}mm`,
            width: "100%"
        }
    });

    let rows = props.certificateDetails.validatedItems.map(x => {

        const datePerformed = new Date(x.datePerformed);

        console.log("Generating row", x, datePerformed);

        try {
            return (
                <View style={styles.historyRow} key={x.serviceInterval}>
                            
                    <View style={styles.historyColumnFirst}>
                        <Text style={styles.tableContentText}>{`${x.serviceInterval}`}</Text>
                    </View>
    
                    <View style={styles.historyColumn}>
                        <Text style={styles.tableContentText}>{x.machineHours}</Text>
                    </View>
    
                    <View style={styles.historyColumn}>
                        <Text style={styles.tableContentText}>{x.workOrderNumber}</Text>
                    </View>
    
                    <View style={styles.historyColumn}>
                        <Text style={styles.tableContentText}>{!!x.datePerformed ? format(datePerformed, 'MM/dd/yyyy') : ''}</Text>
                    </View>
                    
                </View>
            );
        }
        catch (err) {
            console.log("Error while generating certificate", err);
            return <View></View>
        }
    });   

    if(isRTL) {
        rows = props.certificateDetails.validatedItems.map(x => {

            const datePerformed = new Date(x.datePerformed);
            
            return (
                <View style={styles.historyRow} key={x.serviceInterval}>

                    <View style={styles.historyColumn}>
                        <Text style={styles.tableContentText}>{!!x.datePerformed ? format(datePerformed, 'MM/dd/yyyy') : ''}</Text>
                    </View>

                    <View style={styles.historyColumn}>
                        <Text style={styles.tableContentText}>{x.workOrderNumber}</Text>
                    </View>

                    <View style={styles.historyColumn}>
                        <Text style={styles.tableContentText}>{x.machineHours}</Text>
                    </View>
      
                    <View style={styles.historyColumnFirst}>
                        <Text style={styles.tableContentText}>{`${x.serviceInterval}`}</Text>
                    </View>

                </View>
            )
        });   
    }

    const createdOn = new Date(props.certification.createdOn);

    return (
        <Document
            title={`Certification for ${props.certificateDetails.serialNumber}`}
            author={`CAT CCM`}
        >
            <Page size="LETTER" orientation="portrait" style={styles.page}> 

                <View style={styles.summarySection}>

                    <View style={{ width: "100%" }}>
                        <Image src={headerImage}></Image>
                    </View>

                    <View style={styles.summaryRow}>

                        <View style={styles.summaryColumn}>
                            <Text style={styles.summaryItemTitle}>{props.translationSet["Certificate_ProductInformationNumber"]}</Text>
                            <Text style={styles.summaryItemContent}>{props.certificateDetails.serialNumber}</Text>
                        </View>

                        <View style={styles.summaryColumn}>
                            <Text style={styles.summaryItemTitle}>{props.translationSet["Certificate_DateCertified"]}</Text>
                            <Text style={styles.summaryItemContent}>{!!createdOn ? formatDate(createdOn, props.languageCode) : ''}</Text>
                        </View>

                        <View style={styles.summaryColumn}>
                            <Text style={styles.summaryItemTitle}>{props.translationSet["Certificate_ServicingDealer"]}</Text>
                            <Text style={styles.summaryItemContent}>{props.certification.dealer.toUpperCase()}</Text>
                        </View>

                        <View style={styles.summaryColumn}>
                            <Text style={styles.summaryItemTitle}>{props.translationSet["Certificate_Hours"]}</Text>
                            {!!isRTL ? (
                                <>
                                    <Text style={styles.summaryItemContent}>{props.certificateDetails.certifiedAt} {props.translationSet["Certificate_Hours_To"]} 0 {props.translationSet["Certificate_Hours_From"]}</Text>
                                </>
                            ): (
                                <>
                                    <Text style={styles.summaryItemContent}>{props.translationSet["Certificate_Hours_From"]} 0 {props.translationSet["Certificate_Hours_To"]} {props.certificateDetails.certifiedAt}</Text>
                                </>
                            )}
                        </View>

                    </View>
                    
                </View>

                {isRTL ? (
                    <View style={styles.historySection}>

                        <View style={styles.historyTitleRow}>
                            <Text style={styles.historyTitle}>{props.translationSet["Certificate_MaintenanceHistory"]}</Text>
                        </View>

                        <View style={styles.historyRow}>

                            <View style={styles.historyColumn}>
                                <Text style={styles.tableHeaderText}>{props.translationSet["Certificate_Date"]}</Text>
                            </View>

                            <View style={styles.historyColumn}>
                                <Text style={styles.tableHeaderText}>{props.translationSet["Certificate_WorkOrder"]}</Text>
                            </View>

                            <View style={styles.historyColumn}>
                                <Text style={styles.tableHeaderText}>{props.translationSet["Certificate_MachineHours"]}</Text>
                            </View>
                            
                            <View style={styles.historyColumnFirst}>
                                <Text style={styles.tableHeaderText}>{props.translationSet["Certificate_PMInterval"]}</Text>
                            </View>

                        </View>

                        {rows}

                    </View>
                ) : (
                    <View style={styles.historySection}>

                        <View style={styles.historyTitleRow}>
                            <Text style={styles.historyTitle}>{props.translationSet["Certificate_MaintenanceHistory"]}</Text>
                        </View>

                        <View style={styles.historyRow}>
                            
                            <View style={styles.historyColumnFirst}>
                                <Text style={styles.tableHeaderText}>{props.translationSet["Certificate_PMInterval"]}</Text>
                            </View>

                            <View style={styles.historyColumn}>
                                <Text style={styles.tableHeaderText}>{props.translationSet["Certificate_MachineHours"]}</Text>
                            </View>

                            <View style={styles.historyColumn}>
                                <Text style={styles.tableHeaderText}>{props.translationSet["Certificate_WorkOrder"]}</Text>
                            </View>

                            <View style={styles.historyColumn}>
                                <Text style={styles.tableHeaderText}>{props.translationSet["Certificate_Date"]}</Text>
                            </View>
                            
                        </View>

                        {rows}

                    </View>
                )}

                <View style={styles.blankContent}>
                </View>

                <View style={{  display: 'flex', flexDirection: 'row' }}>

                    <View style={styles.footerSection}>

                        {!!isRTL ? (
                            <>
                                <Text style={styles.footerText}>{props.translationSet["Certificate_Footer2"]}</Text>
                                <View style={styles.footerLine}></View>   
                            </>
                        ) : (
                            <>
                                <Text style={styles.footerText}> {props.translationSet["Certificate_Footer2"]} </Text>
                                <View style={styles.footerLine}></View>   
                            </>
                        )}
                        
                        
                    </View>

                    <View style={{ width: "75mm" }}>
                        <Image src={catlogocertificate} style={styles.catLogoImage}></Image>
                    </View>

                </View>

                

            </Page>

        </Document>
    );
};

export default CertificatePDF;